import styled from 'styled-components';

import GradientBorder from '@Components/atoms/GradientBorder/GradientBorder';
import Headline from '@Components/atoms/Headline';
import IconCompetition from '@Components/atoms/Icons/IconCompetition/IconCompetition';
import IconCustomtest from '@Components/atoms/Icons/IconCustomtest/IconCustomtest';
import IconMultiplayer from '@Components/atoms/Icons/IconMultiplayer/IconMultiplayer';
import IconTextpractice from '@Components/atoms/Icons/IconTextpractice/IconTextpractice';
import IconTypingtest from '@Components/atoms/Icons/IconTypingtest/IconTypingtest';
import Text from '@Components/atoms/Text';

export const NavigationBigLink = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${(props) => props.theme.space.default_16};
  padding: ${(props) => props.theme.space.large_32} ${(props) => props.theme.space.default_16};
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.box.background.primary};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme};
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${(props) => props.theme.space.default_16};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledText = styled(Text)`
  margin-left: 10px;
  text-wrap: pretty;
  // margin-bottom: ${(props) => props.theme.space.default_16};
  // display: flex;
  // align-items: center;
  // gap: 10px;
`;

export const StyledIconTypingtest = styled(IconTypingtest)`
  height: 44px;
  width: 44px;
  flex-shrink: 0;
`;
export const StyledIconCompetition = styled(IconCompetition)`
  height: 42px;
  width: 42px;
  flex-shrink: 0;
`;
export const StyledIconTextpractice = styled(IconTextpractice)`
  height: 42px;
  width: 42px;
  flex-shrink: 0;
`;
export const StyledIconMultiplayer = styled(IconMultiplayer)`
  height: 44px;
  width: 44px;
  flex-shrink: 0;
`;
export const StyledIconCustomtest = styled(IconCustomtest)`
  height: 42px;
  width: 42px;
  flex-shrink: 0;
`;

export const StyledGradientBorder = styled(GradientBorder)`
  height: 100%;
`;
