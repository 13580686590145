import { PostOrPage, PostsOrPages } from '@tryghost/content-api';

export interface GhostPosts extends PostsOrPages {
  posts: PostsOrPages;
}

export const fetchPosts = async (params: string): Promise<GhostPosts | undefined> => {
  // todo evaluate caching
  try {
    const posts = await fetch(
      `${process.env.NEXT_PUBLIC_GHOST_BLOG_NEWS_URL}/posts/?key=${process.env.NEXT_PUBLIC_GHOST_BLOG_KEY}&${params}`
    ).then((res) => res.json());
    if (posts.errors) {
      throw new Error(posts.errors[0].message);
    }
    return posts;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const fetchPostBySlug = async (slug: string, params?: string): Promise<PostOrPage | null> => {
  try {
    const posts = await fetch(
      `${process.env.NEXT_PUBLIC_GHOST_BLOG_NEWS_URL}/posts/slug/${slug}?key=${process.env.NEXT_PUBLIC_GHOST_BLOG_KEY}&${params}`
    ).then((res) => res.json());
    if (posts.errors) {
      throw new Error(posts.errors[0].message);
    }
    if (posts.posts.length === 0) {
      throw new Error('No post found');
    }
    return posts.posts[0];
  } catch (error) {
    console.error(error);
    return null;
  }
};

// todo localized url de
export const getBlogPostUrl = (slug: string) => `/blog/${slug}`;

export const defaultImgUrl =
  'https://images.unsplash.com/photo-1455482354547-b410119934cf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMTc3M3wwfDF8c2VhcmNofDIxfHx0eXBld3JpdGVyfGVufDB8fHx8MTY4NDg1MTAyNXww&ixlib=rb-4.0.3&q=80&w=2000';
export const defaultImgCaption =
  'Photo by <a href="https://unsplash.com/@lucabravo?utm_source=10ff">Luca Bravo</a> / <a href="https://unsplash.com/?utm_source=10ff">Unsplash</a>';

export const imageCaption = (post: PostOrPage) => {
  if (!post.feature_image && !post.feature_image_caption) {
    return defaultImgCaption;
  }

  return post.feature_image_caption || '';
};
