import styled from 'styled-components';

export const Root = styled.svg`
  filter: ${(props) => props.theme.palette.icon.saturation};
  transition: filter ${(props) => props.theme.transition.theme};

  g {
    transition: stroke ${(props) => props.theme.transition.theme};
  }
`;
