'use client';

import { PostOrPage } from '@tryghost/content-api';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Col from '@Components/atoms/Col';
import Container from '@Components/atoms/Container';
import Row from '@Components/atoms/Row';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { errorToast } from '@Components/helper/toastHelper';
import { useRouterQuery } from '@Components/helper/useRouterQuery';
import BlogPreview from '@Components/molecules/BlogPreview/BlogPreview';
import NavigationBig from '@Components/molecules/NavigationBig/NavigationBig';

import useRouter from '@Helpers/i18n/useRouter';
import { TextAlign, TextSize } from '@Helpers/types/text';

import {
  BlogSection,
  GraphWrapper,
  Root,
  StyledChartIndex1,
  StyledChartIndex2,
  StyledHeadline,
  StyledRow,
  StyledText,
} from './Index.styles';

interface Props extends PropsWithClassName {
  posts: PostOrPage[];
}

enum SocialAuthCompleteState {
  Error = 'error',
}

enum UnexpectedErrorState {
  Error = 'error',
}

const Index: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const { posts } = props;
  const { t } = useTranslation('index');
  const router = useRouter();
  const { login, user, isGlobalContextInitialized } = useGlobalContext();
  const theme = useTheme();

  const [jwtState] = useRouterQuery('jwt', SocialAuthCompleteState, false);
  const [unexpectedError] = useRouterQuery('unexpected-error', UnexpectedErrorState);

  useEffect(() => {
    if (jwtState === null || !isGlobalContextInitialized) {
      return;
    }

    if (jwtState === SocialAuthCompleteState.Error) {
      errorToast(t('notification.social_auth_error.message', { ns: 'index' }));
      return;
    }

    login(jwtState);

    router.push('/');
  }, [jwtState, isGlobalContextInitialized]);

  useEffect(() => {
    if (unexpectedError === null) {
      return;
    }

    if (unexpectedError === UnexpectedErrorState.Error) {
      errorToast(t('notification.unexpected_error.message'));
      return;
    }
  }, [unexpectedError]);

  return (
    <Root className={props.className} data-testid={'index-page-root'}>
      <section>
        <GraphWrapper>
          {theme.palette.background.chart1Gradient.start && theme.palette.background.chart1Gradient.end && (
            <StyledChartIndex1
              gradientStartColor={theme.palette.background.chart1Gradient.start}
              gradientEndColor={theme.palette.background.chart1Gradient.end}
            />
          )}

          {theme.palette.background.chart2Gradient.start && theme.palette.background.chart2Gradient.end && (
            <StyledChartIndex2
              gradientStartColor={theme.palette.background.chart2Gradient.start}
              gradientEndColor={theme.palette.background.chart2Gradient.end}
            />
          )}
          <Container>
            <StyledRow xsCenter>
              <Col xs={10} sm={8} md={7} lg={6}>
                <StyledHeadline importance={1}>{t('headline')}</StyledHeadline>
              </Col>
              <Col xs={11} sm={10} md={9} lg={8}>
                <StyledText size={TextSize.Large} textAlign={TextAlign.Center}>
                  {t('text.intro')}
                </StyledText>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col xs={12} sm={12} md={12} lg={12}>
                <NavigationBig />
              </Col>
            </StyledRow>
          </Container>
        </GraphWrapper>
      </section>
      <section>
        <BlogSection data-testid="blog-section-root">
          <Container>
            <Row xsCenter>
              <Col xs={11} sm={11} md={11} lg={11}>
                <BlogPreview posts={posts} />
              </Col>
            </Row>
          </Container>
        </BlogSection>
      </section>
    </Root>
  );
};

export default Index;
