import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './ChartIndex1.styles';

interface Props extends PropsWithClassName {
  gradientStartColor: string;
  gradientEndColor: string;
}

const ChartIndex1 = (props: Props): React.ReactElement => {
  return (
    <Root className={props.className} data-testid="chart-index-1-root">
      <svg width="2649" height="480" viewBox="0 0 2649 480" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M436.985 346.953C206.153 357.523 0.932617 479.163 0.932617 479.163V826.288H2648.99V648.805V508.632L2648.68 508.661C2605.23 512.702 2549.42 517.89 2446.17 517.891C2230.28 517.891 2100.33 -0.304749 1803.69 0.721738C1507.05 1.74822 1535.54 494.372 1071.49 487.94C723.872 483.122 632.272 338.011 436.985 346.953Z"
          fill="url(#paint0_linear_237_28851)"
          fillOpacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_237_28851"
            x1="1604"
            y1="-189.781"
            x2="1580.18"
            y2="767.172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={props.gradientStartColor} data-testid="chart-index-1-gradient-start" />
            <stop offset="1" stopColor={props.gradientEndColor} data-testid="chart-index-1-gradient-end" />
          </linearGradient>
        </defs>
      </svg>
    </Root>
  );
};

export default ChartIndex1;
