import styled from 'styled-components';

import ChartIndex1 from '@Components/atoms/Charts/ChartIndex1/ChartIndex1';
import ChartIndex2 from '@Components/atoms/Charts/ChartIndex2/ChartIndex2';
import Headline from '@Components/atoms/Headline';
import Row from '@Components/atoms/Row';
import Text from '@Components/atoms/Text';

export const Root = styled.div`
  margin-top: ${(props) => props.theme.space.xl_64};
  width: 100%;
`;

export const GraphWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  padding-bottom: ${(props) => props.theme.space.xl_64};
`;

export const StyledChartIndex1 = styled(ChartIndex1)`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-55%);
`;

export const StyledChartIndex2 = styled(ChartIndex2)`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-55%);
`;

export const StyledRow = styled(Row)`
  margin-bottom: ${(props) => props.theme.space.xl_64};
`;

export const StyledText = styled(Text)`
  margin-bottom: ${(props) => props.theme.space.large_32};
`;

export const StyledHeadline = styled(Headline)`
  text-align: center;
`;

export const BlogSection = styled.div`
  padding-top: ${(props) => props.theme.space.xl_64};
  background-color: ${({ theme }) => theme.palette.background.accent};
  transition: background-color ${(props) => props.theme.transition.theme};
`;
