import { PostOrPage } from '@tryghost/content-api';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@Components/atoms/Button';
import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button';
import Col from '@Components/atoms/Col';
import DateTime from '@Components/atoms/DateTime/DateTime';
import Divider from '@Components/atoms/Divider';
import Headline from '@Components/atoms/Headline';
import ReadingTime from '@Components/atoms/ReadingTime/ReadingTime';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { defaultImgUrl, getBlogPostUrl, imageCaption } from '@Components/helper/blog';
import { DateTimeFormats } from '@Components/helper/date';
import { Routes } from '@Components/Routes';

import Link from '@Helpers/i18n/Link';
import useRouter from '@Helpers/i18n/useRouter';
import { TextAlign, TextColor, TextSize } from '@Helpers/types/text';

import { Article, ExcerptMeta, ExcerptText, ImageCaption, PostImage, Root } from './BlogPreview.styles';

interface Props extends PropsWithClassName {
  posts: PostOrPage[];
}

const BlogPreview: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const { t } = useTranslation('blog');
  const router = useRouter();
  const { posts } = props;

  if (posts.length === 0) {
    return (
      <Root className={props.className} data-testid="blog-preview-root">
        {t('no_posts')}
      </Root>
    );
  }

  return (
    <Root className={props.className} data-testid="blog-preview-root">
      {posts.map((post, key) => (
        <div key={key}>
          <Article>
            <Row rowGap={RowGap.Large} xsCenter noGutters>
              <Col xs={12} sm={12} md={6} lg={2}>
                <PostImage
                  src={post.feature_image || defaultImgUrl}
                  width={300}
                  height={200}
                  sizes="100vw"
                  alt={post.feature_image_alt || ''}
                />
                <Text size={TextSize.Xs} color={TextColor.Tertiary}>
                  <ImageCaption text={imageCaption(post)} />
                </Text>
              </Col>
              <Col xs={12} sm={12} md={6} lg={8}>
                <Link href={getBlogPostUrl(post.slug)}>
                  <Headline importance={3}>{post.title}</Headline>
                </Link>
                <ExcerptText size={TextSize.Medium} textAlign={TextAlign.Justify}>
                  {post.excerpt}
                </ExcerptText>
                <ExcerptMeta rowGap={RowGap.Large} noGutters>
                  <Text size={TextSize.Small} color={TextColor.Tertiary}>
                    <DateTime date={post.published_at || ''} dateFormat={DateTimeFormats.LocalizedDateFullMonth} />
                  </Text>
                  <ReadingTime readingTime={post.reading_time} />
                </ExcerptMeta>
              </Col>
            </Row>
          </Article>
          <Divider />
        </div>
      ))}
      <Row xsCenter>
        <Button modifier={ButtonModifier.Secondary} type={ButtonType.Button} onClick={() => router.push(Routes.Blog)}>
          {t('all_posts')}
        </Button>
      </Row>
    </Root>
  );
};

export default BlogPreview;
