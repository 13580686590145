import Image from 'next/image';
import styled from 'styled-components';

import HTMLText from '@Components/atoms/HTMLText/HTMLText';
import Row from '@Components/atoms/Row';
import Text from '@Components/atoms/Text';

export const Root = styled.div`
  min-height: 550px;
`;

export const Article = styled.article`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const PostImage = styled(Image)`
  width: 100%;
  height: auto;
  border-radius: ${(props) => props.theme.borderRadius.box};
`;

export const ExcerptText = styled(Text)`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const ExcerptMeta = styled(Row)`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const ImageCaption = styled(HTMLText)`
  a {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;
