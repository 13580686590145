import { sanitize } from 'isomorphic-dompurify';
import React, { PropsWithChildren } from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './HTMLText.styles';

interface Props extends PropsWithClassName {
  text: string;
}

const HTMLText: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const htmlText = sanitize(props.text);
  return (
    <Root className={props.className} data-testid={'html-text-root'} dangerouslySetInnerHTML={{ __html: htmlText }} />
  );
};

export default HTMLText;
