import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './ChartIndex2.styles';

interface Props extends PropsWithClassName {
  gradientStartColor: string;
  gradientEndColor: string;
}

const ChartIndex2 = (props: Props): React.ReactElement => {
  return (
    <Root className={props.className} data-testid="chart-index-2-root">
      <svg width="2649" height="480" viewBox="0 0 2649 480" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M479.612 355.301C226.641 355.301 0.904297 488.053 0.904297 488.053V835.239H2648.97V517.641C2433.08 517.641 2303.13 -0.554261 2006.49 0.472226C1709.84 1.49871 1738.33 494.123 1274.29 487.691C926.668 482.873 675.104 355.301 479.612 355.301Z"
          fill="url(#paint0_linear_237_28850)"
          fillOpacity="0.6"
        />
        <defs>
          <linearGradient
            id="paint0_linear_237_28850"
            x1="1806.8"
            y1="-190.031"
            x2="1782.97"
            y2="766.922"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={props.gradientStartColor} data-testid="chart-index-2-gradient-start" />
            <stop offset="1" stopColor={props.gradientEndColor} data-testid="chart-index-2-gradient-end" />
          </linearGradient>
        </defs>
      </svg>
    </Root>
  );
};

export default ChartIndex2;
